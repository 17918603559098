@import url(https://fonts.googleapis.com/css?family=Fira+Sans:700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Fira Sans', 
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

body{
  font-family: 'Fira Sans', sans-serif;
  background-color: transparent;
}

.background{
  background-color: transparent !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main {
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.social-icons {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.hero-section{
  height: 250px;
  width: 100%;
  border-radius: 16px;
}

.blog-section{
  height: 200px;
  width: 200px;
  border-radius: 16px;
}

.video{
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  z-index: '-100';
}

h1{
  font-family: 'Fira Sans', sans-serif;
  font-size: 64px !important;
  color: #0F0F11;
  z-index: '2';
}

p{
  font-size: 20px;
  z-index: '2';
  text-align: left;
  color: #383842;
}

.caption-text{
  font-size: 12px;
  text-align: left;
  color: #7D7E8D;
}

a{
  font-family: inherit;
  font-size: 18px;
  color: #000 !important;
}

.project-desc{
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  color: #4D4E5A;
}

.contact{
  font-family: 'source-code-pro', sans-serif;
  font-size: 18px;
  color: #23232A;
}

.social-media{
  font-family: 'Fira Sans', sans-serif;
  display: inline-flex;
  padding-top: 1rem;
  font-size: 6px;
  color: rgb(134, 130, 130);
}

.hero{
  /* background-color: #FFE25C; */
  width: 100%;
  height: 300px;
}

.nav-link{
  padding-top: 3rem;
}

.nav-bar{
  position: fixed;
  margin-top: 3rem;
}

.error{
  text-align: center;
  font-size: 200px;
  font-weight: bolder;
  color: rgb(0, 0, 0);
}

Nav{
  font-size: light;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #383842;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media only screen and (max-width: 700px) {

  body {
    padding-right: 15px;
    padding-left: 15px;
    background-color: transparent;
  }

  .video{
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    margin-left: -14rem;
    z-index: '-100';
  }

  .social-media{
    font-family: 'Fira Sans', sans-serif;
    display: inline-flex;
    font-size: 6px;
    color: rgb(134, 130, 130);
    padding-top: 1rem;
  }
}

